














































































































import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter, TestimonialFilter } from 'client-website-ts-library/filters';
import {
  Listing, ListingCategory, ListingStatus, Office, Profile, Testimonial, WebsiteLevel, WebsiteProfileDisplayType,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';
import Offices from '@/components/Offices.vue';
import Profiles from '@/components/Profiles.vue';
import ImageBox from '@/components/ImageBox.vue';
import Awards from '@/components/Awards.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { ProfileFilter } from 'client-website-ts-library/filters/ProfileFilter';
import GridNavigation from '@/components/GridNavigation.vue';
import GridNavigationItem from '@/components/GridNavigationItem.vue';
import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue';
import TestimonialCycler from '@/components/Testimonials/TestimonialCycler.vue';
import AgentCardCycler from '@/components/Cycler/AgentCardCycler.vue';
import LinkButton from '@/components/UI/LinkButton.vue';
import NavigationLink from '@/components/Common/NavigationLink.vue';
import { ItemCyclerImageSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';
import ItemCycler from '@/components/Cycler/ItemCycler.vue';

@Component({
  components: {
    Offices,
    ImageBox,
    Awards,
    Profiles,
    VideoPlayer,
    GridNavigation,
    GridNavigationItem,
    ListingCardCycler,
    TestimonialCycler,
    AgentCardCycler,
    LinkButton,
    NavigationLink,
    ItemCycler,
  },
})
export default class Home extends Mixins(View) {
  private offices: Office[] = [];

  private profiles: Profile[] = [];

  private readonly inRealEstate!: string;

  private testimonials: Testimonial[] | null = null;

  private salesListings: Listing[] | null = null;

  private rentalListings: Listing[] | null = null;

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
  ];

  get month(): number {
    return (new Date().getMonth() + 1);
  }

  private testimonialFilter: TestimonialFilter = new TestimonialFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.Website.Settings!.WebsiteId,
  });

  private salesListingFilter: ListingFilter = new ListingFilter({
    Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    PageSize: 6,
  });

  private rentalListingFilter: ListingFilter = new ListingFilter({
    Categories: [ListingCategory.ResidentialRental],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    PageSize: 6,
  });

  shuffle(array: Office[]): Office[] {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  mounted() {
    API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
      this.offices = this.shuffle(offices);
    });

    API.Profiles.Search(
      new ProfileFilter({
        SearchLevel: WebsiteLevel.Office,
        SearchGuid: Config.Website.Settings!.WebsiteId,
      }),
      true,
    ).then((profiles) => {
      this.profiles = profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Agent);
    });

    // this.loadTestimonials();
    this.loadSalesListings();
    this.loadRentalListings();

    let scr: HTMLScriptElement | null = document.getElementById('scrCoreLogicIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://dpr.leadplus.com.au/main.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrCoreLogicIngegration';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }

  loadTestimonials(): void {
    API.Testimonials.Search(this.testimonialFilter).then((testimonials) => {
      this.testimonials = testimonials;
    });
  }

  loadSalesListings(): void {
    API.Listings.Search(this.salesListingFilter).then((listings) => {
      this.salesListings = listings.Items;
    });
  }

  loadRentalListings(): void {
    API.Listings.Search(this.rentalListingFilter).then((listings) => {
      this.rentalListings = listings.Items;
    });
    // End of export
  }
}
