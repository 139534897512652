






import { TestimonialFilter } from 'client-website-ts-library/filters';
import { API } from 'client-website-ts-library/services';
import { Testimonial } from 'client-website-ts-library/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TestimonialCard from './TestimonialCard.vue';

@Component({
  components: {
    TestimonialCard,
  },
})
export default class TestimonialCards extends Vue {
  private testimonials: Testimonial[] = [];

  @Prop()
  private readonly filter!: TestimonialFilter;

  mounted() {
    this.updateTestimonials();
  }

  @Watch('filter', { deep: true })
  updateTestimonials() {
    API.Testimonials.Search(this.filter, true).then((testimonials) => {
      const nicole = testimonials.filter((t) => t.UserId === '0b2007c3-0227-478c-97c1-29a5347fab9e').slice(0, 10);

      const allAgents = nicole.concat(testimonials.filter((t) => t.UserId !== '0b2007c3-0227-478c-97c1-29a5347fab9e'));

      this.testimonials = this.shuffle(allAgents);

      this.$emit('got_testimonial_count', testimonials.length);

      this.$emit('got_testimonials', testimonials);
    });
  }

  shuffle(array: Testimonial[]): Testimonial[] {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}
